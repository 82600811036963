<div #welcomeMasterDiv class="welcome-master-div" (window:resize)="onResize()">

    <div id="whoWeAre" class="overflow-hidden text-center welcome-margin-padding gold-back">
        <h2 class="margin-left-0 margin-top-0 astropod-title1">Privacy Policy</h2>
        <p>Our Monami Jounce game does not collect any personally identifiable information from app users. Apps developed by Astropod Studios may collect non-personally identifiable information from devices that have installed them, but that information will only be used to deliver services requested by users, such as content and updates within the app, or to measure the app's performance or stability.</p>
  
      </div>  
  
</div>

