import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  { path: 'welcome',     component: WelcomeComponent  },
  { path: 'privacy',     component: PrivacyComponent  },
  { path: '**', pathMatch:'full', redirectTo: '/welcome' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
