//Original version created by Cory Rylan: https://coryrylan.com/blog/angular-2-form-builder-and-validation-management
import { AbstractControl, FormGroup } from '@angular/forms';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

export class ValidationService {
     
    static getValidatorErrorMessage(code: string) {
        let config = {
            'required': 'Required',
            'invalidCreditCard': 'Is invalid credit card number',
            'invalidEmailAddress': 'Invalid email address',
            'invalidPassword': 'Invalid password. Password must be at least 11 characters long, and contain a number and a special character.'
        };
        return config[code];
    }

    static creditCardValidator(control: AbstractControl) {
        // Visa, MasterCard, American Express, Diners Club, Discover, JCB
        if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
            return null;
        } else {
            return { 'invalidCreditCard': true };
        }
    }
     
    static emailValidator(control: AbstractControl) {
        // RFC 2822 compliant regex
        if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return null;
        } else {
            return { 'invalidEmailAddress': true };
        }
    }
     
    static passwordValidator(control: AbstractControl) {
        // {11,100}           - Assert password is between 11 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*.,?]{11,100}$/)) {
            if (control.value.match(/[^a-zA-Z0-9]/)) { //includes at least one special characters
                return null;
            }
            else {
                return { 'invalidPassword': true };
            }
        } else {
            return { 'invalidPassword': true };
        }
    }

    static blankPasswordValidator(control: AbstractControl) {
        // {11,100}           - Assert password is between 11 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value.length == 0) {
            return null;
        }
        if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*.,?]{11,100}$/)) {
            if (control.value.match(/[^a-zA-Z0-9]/)) { //includes at least one special characters
                return null;
            }
            else {
                return { 'invalidPassword': true };
            }
        } else {
            return { 'invalidPassword': true };
        }
    }

    static checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmPassword').value;

        return pass === confirmPass ? null : { notSame: true }     
    }

    static phoneValidator(number): any {
        if (number.pristine) {
           return null;
        }
        const PHONE_REGEXP = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
        const PHONE_REGEXP_SP = /^(\([0-9]{3}\) |[0-9]{3} )[0-9]{3} [0-9]{4}$/;
        const PHONE_REGEXP_NL = /^(\([0-9]{3}\) |[0-9]{3})[0-9]{3}[0-9]{4}$/;
        number.markAsTouched();
        if (number.value.length == 0) {
            return null;
        }
        else if (PHONE_REGEXP.test(number.value)) {
           return null;
        }
        else if (PHONE_REGEXP_SP.test(number.value)) {
            return null;
        }
        else if (PHONE_REGEXP_NL.test(number.value)) {
            return null;
        }
        return {
           invalidNumber: true
        };
     }
}