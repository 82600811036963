import { Component, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AlertPromise } from 'selenium-webdriver';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactRequest } from './contactRequest';
import { ValidationService } from './validation.service';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, timeout, filter } from 'rxjs/operators';
import { Observable, EMPTY, of, from, throwError } from "rxjs";

@Component({
  host: {
    '(document:click)': 'onClick($event)',
  },  
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  welcomeChild: any;
  browser = "";
  os = "";
  title = 'astropod-app';
  soLeftMarginStr: string = "";
  topStr: string = "";
  msgTopStr: string = "52px";
  top: number = 0;
  marginStr: string = "";
  widthStr: string = "";
  contactFormPop: FormGroup;
  errorMsg: string = "";
  topStrContact: string = "52px";

  constructor(
    public cd: ChangeDetectorRef,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private http: HttpClient, 
    public router: Router) {}

  @ViewChild('popOverWelcomeHamburgerMenu') popOverWelcomeHamburgerMenu: ElementRef;    
  @ViewChild('popOverContact') popOverContact: ElementRef;    
  @ViewChild('popError') popError: ElementRef;    
  @ViewChild('welcomeHamburgerButton') welcomeHamburgerButton: ElementRef;

  resetContact() {
    this.contactFormPop = this.formBuilder.group({
      name:    ["", [Validators.required]],
      phoneNumber: ["", [ValidationService.phoneValidator]],
      email:    ["", [Validators.required, ValidationService.emailValidator]],
      comment:    [""],
    },
    { updateOn: "blur", validator: ValidationService.checkPasswords });
  }  

  ngAfterViewInit() {
    this.popOverWelcomeHamburgerMenu.nativeElement.style.display='none';
    this.popOverContact.nativeElement.style.display='none';
    this.popError.nativeElement.style.display='none';
    // this.errorMsg = "hi there";
  }

  ngOnInit() {
    var thisWin = this;
    this.os = this.getMobileOperatingSystem();
    if (this.os == "unknown") {
      if (navigator.appVersion.indexOf("Win")!=-1) this.os="Windows";
      if (navigator.appVersion.indexOf("Mac")!=-1) this.os="MacOS";
      if (navigator.appVersion.indexOf("X11")!=-1) this.os="UNIX";
      if (navigator.appVersion.indexOf("Linux")!=-1) this.os="Linux";
    }    
    if(navigator.userAgent.match('CriOS')) {
      this.browser = "Chrome";
    }

    window.addEventListener('orientationchange', function () {
      var originalBodyStyle = getComputedStyle(document.body).getPropertyValue('display');
      document.body.style.display='none';
      setTimeout(function () {
        document.body.style.display = originalBodyStyle;
        thisWin.resizePopups();
        thisWin.cd.detectChanges();
      }, 10);
    });

    this.resetContact();
    this.onResize(null);

  }  

  // onClick(event) {
  //   if ((this.popOverWelcomeHamburgerMenu != null) && (!this.popOverWelcomeHamburgerMenu.nativeElement.contains(event.target))) {
  //     if (this.popOverWelcomeHamburgerMenu.nativeElement.style.display != 'none') {
  //       this.popOverWelcomeHamburgerMenu.nativeElement.style.display='none';
  //     }
  //   }
  //   else if ((this.welcomeHamburgerButton) && (this.welcomeHamburgerButton.nativeElement.contains(event.target))) {
  //     if (this.popOverWelcomeHamburgerMenu.nativeElement.style.display == 'none') {
  //       this.popOverWelcomeHamburgerMenu.nativeElement.style.display='block';
  //     }
  //   }    
  //  }

   onClick(event) {
    if ((this.welcomeHamburgerButton) && (this.welcomeHamburgerButton.nativeElement.contains(event.target))) {
      if (this.popOverWelcomeHamburgerMenu.nativeElement.style.display == 'none') {
        this.popOverWelcomeHamburgerMenu.nativeElement.style.display='block';
      }
      else {
        this.popOverWelcomeHamburgerMenu.nativeElement.style.display='none';
      }
    }   
    else if ((this.popOverWelcomeHamburgerMenu != null) && (this.popOverWelcomeHamburgerMenu.nativeElement.contains(event.target))) {
      if (this.popOverWelcomeHamburgerMenu.nativeElement.style.display != 'none') {
        this.popOverWelcomeHamburgerMenu.nativeElement.style.display='none';
      }
    }    
    else {
      this.popOverWelcomeHamburgerMenu.nativeElement.style.display='none';
    }

   }   

  dismissWelcomeHamburgerMenu() {
    this.popOverWelcomeHamburgerMenu.nativeElement.style.display='none';
  }

  public getWinWidth() {
    let inner = window.innerWidth;
    let outer = window.outerWidth;
    // if (inner < outer) return inner;
    // else return outer;
    if ((this.os == "iOS") && (this.browser == "Chrome")) {
      if (inner > outer) return inner;
      else return outer;
    }
    return inner;
  }

  public getWinHeight() {
    let inner = window.innerHeight;
    let outer = window.outerHeight;

    if ((this.os == "iOS") && (this.browser == "Chrome")) {
      if (inner > outer) return inner;
      else return outer;
    }
    if (inner < outer) return inner;
    else return outer;
  }  

  resizePopups() {
    let soLeftStr = (this.getWinWidth() - 200);
    this.soLeftMarginStr = String(soLeftStr) + "px";
    this.top = 20;
    this.topStr = String(this.top) + "px";
    var widthVal = this.getWinWidth();
    if (widthVal > 450) {
      widthVal = 450;
    }
    var marginVal = (this.getWinWidth() - widthVal)/2;
    this.marginStr = String(marginVal) + "px";
    this.widthStr = String(widthVal) + "px";
  }  

  onResize(event) {
    var thisWin = this;
    setTimeout(function () {
      thisWin.resizePopups();
    },150);
  }  

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent)) {
          return "iOS";
      }
  
      return "unknown";
  }  

  // onWelcomeHamburgerMenuClick() {
  //   this.popOverWelcomeHamburgerMenu.nativeElement.style.display='block';
  // }

  clickAbout() {
    // location.href = "#howItWorks";
    var top = document.getElementById("about").offsetTop; 
    this.dismissWelcomeHamburgerMenu();
    window.scrollTo(0, top); 
  }

  clickGames() {
    var top = document.getElementById("games").offsetTop; 
    this.dismissWelcomeHamburgerMenu();
    window.scrollTo(0, top); 
  }  

  public goToWelcome() {
    this.router.navigateByUrl("/welcome");
    sessionStorage.setItem("currentPage", "welcome");
  }
  public goToPrivacy() {
    this.router.navigateByUrl("/privacy");
    sessionStorage.setItem("currentPage", "privacy");
  }  


  showContact() {
    this.popOverContact.nativeElement.style.display='inline';
    this.popOverWelcomeHamburgerMenu.nativeElement.style.display='none';
    let onElement = this.renderer.selectRootElement('#name');
    onElement.focus();
  }

  dismissContact() {
    this.popOverContact.nativeElement.style.display='none';
    this.resetContact();
  }  

  onContactSubmit({ value, valid }: { value: ContactRequest, valid: boolean }) {
    if (value.comment != null && value.comment.length > 0) {
      this.errorMsg = "";
      this.sendEmail(value);
      this.dismissContact();
      this.showDisappearingInfoMsg("Your contact request was sent");
    }
    else {
      this.errorMsg = "Please enter a comment or question";
    }
  }

  showDisappearingInfoMsg(msg) {
    this.errorMsg = msg;
    this.popError.nativeElement.style.display='inline';
    var thisWin = this;
    setTimeout(function () {
      thisWin.dismissError();
      thisWin.errorMsg = "";
      thisWin.cd.detectChanges();
    }, 4000);
  }  

  dismissError() {
    this.popError.nativeElement.style.display='none';
    this.errorMsg = "";
  }  

  sendEmail(cr: ContactRequest) {
    // alert("cr: " + JSON.stringify(cr));
    var thisWin = this;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json'});
    let options = { headers: headers };

    // var emailRequest = new EmailRequest();
    // var emailContent = new Content();
    // var simple = new SimpleType();
    // var body = new BodyType();
    // var text = new HtmlType();
    // simple.Body = body;
    // body.Text = text;
    // text.Data = JSON.stringify(cr);
    // emailContent.Simple = simple;


    // emailRequest.Content = emailContent;
    // var emailDest  = new Destination();
    // emailDest.ToAddresses = ["admin@astropodstudios.com"];
    // emailRequest.Destination = emailDest;
    // emailRequest.ReplyToAddresses = ["admin@astropodstudios.com"];


    thisWin.postEmail(cr).subscribe(uResp => {
      // alert('postEmail response: ' + uResp);
    });

    // return this.http.get<any>(endpoint, options);



  }

  private postEmail(cr: ContactRequest) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json'});
    let options = { headers: headers };
    var endpoint = "https://5eegfjjfar2p235un6q6dsfg440qibgh.lambda-url.us-east-1.on.aws/";

    endpoint = endpoint + "?comment=" + cr.comment + "&name=" + cr.name + "&email=" + cr.email + "&phoneNumber=" + cr.phoneNumber;
    endpoint = encodeURI(endpoint);

    return this.http.get<any>(endpoint, options);

    // return this.http.post<any>(endpoint, cr, options)
    // .pipe(
    //   map((res: any) => {
    //     return res;
    //   }),
    //   catchError((error: HttpErrorResponse) => {
    //     alert("error caught");
    //     if (error.error instanceof Error) {
    //       // A client-side or network error occurred. Handle it accordingly.
    //       console.error('An error occurred:', error.error.message);
    //     } else {
    //       // The backend returned an unsuccessful response code.
    //       // The response body may contain clues as to what went wrong,
    //       console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
    //     }

    //     // If you want to return a new response:
    //     //return of(new HttpResponse({body: [{name: "Default value..."}]}));

    //     // If you want to return the error on the upper level:
    //     //return throwError(error);

    //     // or just return nothing:
    //     return EMPTY;
    //   })
    // );
  }

  // private handleError(error: HttpErrorResponse) {
  //   alert("handle error");
  //   if (error.status === 0) {
  //     // A client-side or network error occurred. Handle it accordingly.
  //     console.error('An error occurred:', error.error);
  //   } else {
  //     // The backend returned an unsuccessful response code.
  //     // The response body may contain clues as to what went wrong.
  //     console.error(
  //       `Backend returned code ${error.status}, body was: `, error.error);
  //   }
  //   // Return an observable with a user-facing error message.
  //   return throwError(() => new Error('Something bad happened; please try again later.'));
  // }

}
