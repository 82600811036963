import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef, ViewRef } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(
    @Inject(AppComponent) private privacyParent: AppComponent,
    public router: Router,
    public cd: ChangeDetectorRef,
  ) { }

  heightStr = "";

  ngOnInit(): void {
  }

  public onResize() {
    var thisWin = this;
    setTimeout(function () {
      thisWin.resizeLogic();
    }, 150);
  }


  resizeLogic() {
    var winH = this.privacyParent.getWinHeight();
    var winW = this.privacyParent.getWinWidth();

    // console.log(winW/winH);
    // if (((winW/winH) > 2.1) || (winH < 550)) {
    //   //hide cityscape
    //   this.cityScapeImage.nativeElement.style.display = 'none';
    // }
    // else {
    //   this.cityScapeImage.nativeElement.style.display = 'inline';
    // }
    // this.cityScapeImage.nativeElement.style.maxHeight = String(winH/2.5) + "px";

    var numPix = 8;
    if (winW > 414) {
      numPix = this.convertRemToPixels(2);
    }
    // winH = winH - 52 - 2*numPix;
    winH = winH - 52;
    this.heightStr = String(winH) + "px";
    // this.welcomeParent.dismissWelcomeHamburgerMenu();
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }  

  convertRemToPixels(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }    

}
