import { Component, OnInit, Inject, ViewChild, ElementRef, ChangeDetectorRef, ViewRef } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(
    @Inject(AppComponent) private welcomeParent: AppComponent,
    public router: Router,
    public cd: ChangeDetectorRef,
  ) { }

  heightStr = "";

  @ViewChild('welcomeMasterDiv') welcomeMasterDiv: ElementRef;
  @ViewChild('rotatingImageDiv') rotatingImageDiv: ElementRef;  

  ngOnInit() {
    this.welcomeParent.welcomeChild = this;
    this.onResize();
    this.welcomeParent.cd.detectChanges();

    // this.logoImageAddress = "../assets/welcome-assets/logo-animatedWithText-tagline.svg?" + "a=" + Math.floor(Math.random() * 10000);  
    // this.logoImageAddress = "../assets/welcome-assets/logo-withText-tagline.svg";  

    var thisWin = this;
    // this.rotatingImageDiv.nativeElement.style.backgroundImage = 'url(' + '../assets/welcome-assets/rotating/03_in-town.jpeg' + ')';

    // this.changeImage();
    this.rotatingImageDiv.nativeElement.style.backgroundImage = 'url(' + '../assets/outerspace.jpg' + ')';
  }

  // changeImage() {
  //   var imageNum = Math.floor(Math.random() * 30) + 1;
  //   while (imageNum == this.imageNumber) {
  //     imageNum = Math.floor(Math.random() * 30) + 1;
  //   }
  //   this.imageNumber = imageNum;
  //   var numStr = this.pad(imageNum, 2);
  //   this.rotatingImageDiv.nativeElement.style.backgroundImage = 'url(' + 'https://d2d8h0xzr6m94f.cloudfront.net/' + numStr + '.jpeg' + ')';

  //   var thisWin = this;
  //   setTimeout(function () {
  //     thisWin.changeImage();
  //   }, 15000);
  // }


  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }


  public onResize() {
    var thisWin = this;
    setTimeout(function () {
      thisWin.resizeLogic();
    }, 150);
  }


  public goToPrivacy() {
    this.router.navigateByUrl("/privacy");
    sessionStorage.setItem("currentPage", "privacy");
  }

  resizeLogic() {
    var winH = this.welcomeParent.getWinHeight();
    var winW = this.welcomeParent.getWinWidth();

    // console.log(winW/winH);
    // if (((winW/winH) > 2.1) || (winH < 550)) {
    //   //hide cityscape
    //   this.cityScapeImage.nativeElement.style.display = 'none';
    // }
    // else {
    //   this.cityScapeImage.nativeElement.style.display = 'inline';
    // }
    // this.cityScapeImage.nativeElement.style.maxHeight = String(winH/2.5) + "px";

    var numPix = 8;
    if (winW > 414) {
      numPix = this.convertRemToPixels(2);
    }
    // winH = winH - 52 - 2*numPix;
    winH = winH - 52;
    this.heightStr = String(winH) + "px";
    // this.welcomeParent.dismissWelcomeHamburgerMenu();
    if (this.cd && !(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  convertRemToPixels(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }  

}
