<nav class="navbar navbar-default navbar-top">

  <div #navbarheader class="navbar-header">
    <div style="float: left">
      <a (click)="goToWelcome();"><img src="assets/astropod.png" class="logo" /></a>
      <div class="title-script" (click)="goToWelcome();">Astropod Studios</div>
   </div>

    

    <input #welcomeHamburgerButton type="image" class="myimbbtn margin-bottom-0 float-right welcome-hamburger"  src="assets/hamburger.png" title="Menu"> 

    <div class="welcome-font header-menu" >
      <button class="header-btn margin-bottom-0 welcome-menu" (click)="clickAbout();">About</button> 
      <button class="header-btn margin-bottom-0 welcome-menu" (click)="clickGames();">Games</button>
      <button class="header-btn margin-bottom-0 welcome-menu" (click)="showContact();">Contact</button> 
    </div>


  </div>
</nav>

<div #popOverWelcomeHamburgerMenu class="welcome-popover-div" [style.margin-top]="topStr" [style.margin-left]="soLeftMarginStr">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div>
      <button class="welcome-btn margin-bottom-0" (click)="clickAbout();">About</button> 
      <button class="welcome-btn margin-bottom-0 " (click)="clickGames();">Games</button>
      <button class="welcome-btn margin-bottom-0" (click)="showContact();">Contact</button> 
    </div>
  </div>
</div>  

<div #popOverContact class="contact-popover-div" [style.width]="widthStr" [style.margin-left]="marginStr" [style.margin-right]="marginStr" [style.margin-top]="topStrContact" (window:resize)="onResize($event)" >
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div>
        <button class="x-button" aria-label="Close Registration" (click)="dismissContact()">&times;</button>
        <h3>Contact Us</h3>
          <form [formGroup]="contactFormPop" (ngSubmit)="onContactSubmit(contactFormPop)">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margin-bottom-10">
              <input type="text" class="form-control" placeholder="Enter name" formControlName="name" name="name" id="name"/>
              <div class="alert alert-danger" *ngIf="contactFormPop.controls.name.invalid && (contactFormPop.controls.name.dirty || contactFormPop.controls.name.touched)">Name is required</div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margin-bottom-10">
              <input type="text" class="form-control" placeholder="Enter email" formControlName="email" name="email" id="contactemail"/>
              <div class="alert alert-danger" *ngIf="contactFormPop.controls.email.invalid && (contactFormPop.controls.email.dirty || contactFormPop.controls.email.touched)">A valid email is required</div>
            </div>              
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margin-bottom-10">
              <input type="text" class="form-control" placeholder="Enter 10-digit phone number" formControlName="phoneNumber" name="phoneNumber" id="phoneNumber"/>
              <div class="alert alert-danger" *ngIf="contactFormPop.controls.phoneNumber.invalid && (contactFormPop.controls.phoneNumber.dirty || contactFormPop.controls.phoneNumber.touched)">Please enter a valid phone number</div>
            </div>       
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margin-bottom-10">
              <textarea class="agent-textarea" formControlName="comment" name="comment" id="comment" placeholder="Enter comment or question" rows="4" cols="36" maxlength="500"></textarea>
            </div>   
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 margin-bottom-10" *ngIf="errorMsg.length > 0">
              <div #errMsg2 class="alert alert-danger" >{{ errorMsg }}</div>
            </div>                     
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <button type="submit" class="mybtn mybtn-submit reset" [disabled]="!contactFormPop.valid">Submit</button> 
            </div>              
        </form>  
    </div>
  </div>
</div>  

<div #popError class="signin-popover-div pop-error-div" [style.width]="widthStr" [style.margin-top]="msgTopStr" [style.margin-left]="marginStr" [style.margin-right]="marginStr" (window:resize)="onResize($event)">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <div>
        <button class="x-button-msg" aria-label="Close Error" (click)="dismissError()">&times;</button>
        <div class="error-msg-text">{{ errorMsg }}</div>
    </div>
  </div>
</div>

<main>
  <router-outlet></router-outlet>
</main>

