<div #welcomeMasterDiv class="welcome-master-div" (window:resize)="onResize()">

  <div #rotatingImageDiv [style.height]="heightStr" class="rotating-image image-position position-relative overflow-hidden text-center">   <!-- welcome-margin-padding -->
    <div #animatedImageDiv class="col-md-5 welcome-font">

      <img class="logo-main gradient-background" src="../assets/astropodstudios.png" style="max-height: 380px; width: auto;">
    </div>

  </div>  

  <!-- <div id="astropodStudios" class="astropod-header overflow-hidden text-center welcome-margin-padding gray-back">  
      <img class="central-graphic margin-top-20 margin-bottom-20" src="../assets/astropodstudios.png">
    </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>   -->

  <div id="about" class="overflow-hidden text-center welcome-margin-padding yellow-back">
    <h2 class="margin-left-0 margin-top-0 astropod-title1">Who We Are</h2>
    <p>Astropod Studios is a developer of casual games based in Atlanta, GA.   </p>

    <p>We combine Unity with mobile and cloud technologies and our original art work to produce mobile games that people can enjoy at shorter or longer intervals throughout the day.  </p>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"></div>  

  <div id="games" class="overflow-hidden text-center welcome-margin-padding blue-back">
    <h2 class="margin-left-0 margin-top-0 astropod-title1">Our Games</h2>
    <p class="margin-top-30">Please download Monami Mounce for iOS and Android!</p>
    <img class="central-graphic restrain-image" src="../assets/monamijouncelarge2.png">
    <div style="width: 100%; margin-top: 30px">
        <a href="https://apps.apple.com/us/app/monami-jounce/id1523696942" target="_blank"> <img src="../assets/iosAppStore.png"></a>
     </div>
     <div style="width: 100%; margin-top: 20px; margin-bottom: 20px">
        <a href="https://play.google.com/store/apps/details?id=com.AstropodStudios.MonamiJounce" target="_blank"> <img src="../assets/googlePlayStore.png"></a> 
        <!-- https://play.google.com/store/apps/details?id=com.dopsle.mobileapp -->
     </div>    
  </div>
  
  <div class="welcome-bottom">
    <div class="welcome-center margin-top-20">
      <a (click)="goToPrivacy();" class="footer-link2 color-dark-gray">Privacy Policy</a>
    </div>  
    <div class="welcome-center  margin-top-10 margin-bottom-20 ">
        <small class="d-block mb-3 color-dark-gray">&copy; 2022 Astropod Studios</small>
    </div>
  </div>


</div>